import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import useRichText from "../Hooks/useRichText"
import useViewport from "../Hooks/useViewport"

const useLegal = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulPoliticaDePrivacidad {
        edges {
          node {
            texto {
              raw
            }
            titular
          }
        }
      }
    }
  `)

  return data
}

const Privacidad = () => {
  const data = useLegal()
  useViewport()

  return (
    <Layout>
      <div className="AvisoLegal">
        <h1>{data.allContentfulPoliticaDePrivacidad.edges[0].node.titular}</h1>
        <div className="AvisoLegal--text">
          <p>{useRichText(data.allContentfulPoliticaDePrivacidad.edges[0].node.texto)}</p>
        </div>
      </div>
    </Layout>
  )
}

export default Privacidad
